import { Card, Container } from 'react-bootstrap';

function LogoutPage() {
    return (
        <Container
            fluid="xxl"
            as="div"
            className="p-5 d-flex justify-content-center align-items-center "
        >
            <Card style={{ width: '30rem' }}>
                <Card.Body>
                    <Card.Title>Odhlášení dokončeno</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                        Vaše odhlášení proběhlo úspěšně{' '}
                    </Card.Subtitle>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default LogoutPage;
