import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ApplicationListPage from './pages/ApplicationList/application-list';
import ApplicationDetailPage from './pages/ApplicationDetail/application-detail';
import {HouseDoor} from 'react-bootstrap-icons';
import App from './App';
import ProcessInstanceModificationPage
    from './pages/administration/ProcessInstanceModification/process-instance-modification';
import TaskAssignnPage from './pages/administration/TaskAssign/task-assign';
import ProcessInstanceMigrationPage from './pages/administration/ProcessInstanceMigration/process-instance-migration';
import TryLoginPage from './pages/TryLogin/try-login';
import LoginPage from './pages/Login/LoginPage';
import PayoutDetailPage from './pages/PayoutDetail/payout-detail';
import {TitleRegisterPage} from './pages/TitleRegister/title-register';
import {RootState, useAppSelector} from './store/store';
import {hasIntersection} from './utils/utils';
import {ET_ROLES, MP_APPLICATION_ROLES, PAYOUTS_LIST_ROLES,} from './services/entities/User';
import {LoadingIndicator} from './components/loadingIndicator/LoadingIndicator';
import PayoutsList from './pages/PayoutsList/payouts-list';
import {IS_KOOP} from './services/constants';
import LogoutPage from './pages/Logout/LogoutPage';

export const ROUTE_LIS_TEXTS = {
    home: <HouseDoor />,
    appList: <HouseDoor />,
    appDetail: 'Žádost o provizi',
};

function RouteList() {
    const user = useAppSelector((s: RootState) => s.user);

    let indexElement = <LoadingIndicator title="Načítání dat uživatele" />;
    if (user) {
        if (hasIntersection(user.roles, MP_APPLICATION_ROLES))
            indexElement = <ApplicationListPage />;
        else if (hasIntersection(user.roles, ET_ROLES))
            indexElement = <TitleRegisterPage />;
        else if (hasIntersection(user.roles, PAYOUTS_LIST_ROLES))
            indexElement = <PayoutsList />;
        else
            indexElement = (
                <h3 className="p-3 text-danger">Uživatel nemá potřebné role</h3>
            );
    }
    return (
        <BrowserRouter>
            <Routes>
                {/*<Route path='/' element={<TryLoginPage />} />*/}
                <Route path="/logout" element={<LogoutPage />} />
                <Route path="/" element={<App />}>
                    <Route
                        path="/login/:previousLocation"
                        element={<LoginPage />}
                    />
                    <Route path="/login/" element={<LoginPage />} />

                    <Route path="/" element={indexElement} />
                    <Route path="/payouts" element={<PayoutsList />} />
                    <Route
                        path="/application/detail/:applicationId"
                        element={<ApplicationDetailPage />}
                    />
                    <Route
                        path="/application/detail/:applicationId/:taskId"
                        element={<ApplicationDetailPage />}
                    />
                    <Route
                        path="/application/detail/:applicationId/payouts"
                        element={<ApplicationDetailPage isPayouts />}
                    />
                    <Route
                        path="/application/task/:applicationId"
                        element={<ApplicationDetailPage />}
                    />
                    <Route
                        path="/application/payout/detail/:applicationId/:payoutId"
                        element={<PayoutDetailPage />}
                    />
                    <Route
                        path="/administration/process-instance-modification"
                        element={<ProcessInstanceModificationPage />}
                    />
                    <Route
                        path="/administration/task-assign"
                        element={<TaskAssignnPage />}
                    />
                    <Route
                        path="/administration/process-instance-migration"
                        element={<ProcessInstanceMigrationPage />}
                    />
                    <Route
                        path="/title-register"
                        element={<TitleRegisterPage />}
                    />
                    <Route path="/try-login" element={<TryLoginPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export const NAV = {
    INDEX: '/',
    GUIDE: IS_KOOP ? '/assets/userGuideKoop.pdf' : '/assets/userGuideCpp.pdf',
    APPLICATION_TASK: (id) => `/application/task/${id}`,
    APPLICATION_DETAIL: (id) => `/application/detail/${id}`,
    APPLICATION_DETAIL_WITH_TASK: (id, taskId) =>
        `/application/detail/${id}/${taskId}`,
    APPLICATION_DETAIL_PAYOUTS: (id) => `/application/detail/${id}/payouts`,
    PAYOUT_DETAIL: (appId, payoutId) =>
        `/application/payout/detail/${appId}/${payoutId}`,
    PAYOUTS: '/payouts',
    PROCESS_MOD: '/administration/process-instance-modification',
    TASK_ASSIGN: '/administration/task-assign',
    PROCESS_MIGRATE: '/administration/process-instance-migration',
    TITLE_REGISTER: '/title-register',
    LOGIN_START: '/login',
    LOGIN: (previousLocation) =>
        `/login?previousLocation=${encodeURIComponent(previousLocation)}`,
    LOGOUT: '/logout',
};

export default RouteList;
